import axios from "@/util/axios";
let { get, post, form } = axios
// 跟投提交
export const lotteryBet = data => post("/betRecord/bet", data)
// 获取当前期数时间信息
export const getLotteryPeriodInfo = data => get("/betRecord/getLotteryPeriodInfo", data)
// 获取聊天记录
export const getChatList = data => post("/cashBack/chatList", data)
// 获取好友列表
export const getChatUserFriends = data => get("/cashBack/chatUserFriends", data)
// 获取群列表
export const getChatGroups = data => get("/cashBack/chatGroups", data)
// 查询好友
export const searchFriend = data => get("/cashBack/searchFriend", data)
// 添加好友
export const requestFriend = data => get("/cashBack/requestFriend", data)
// 私聊——发送
export const chatToOtherUser = data => post("/cashBack/chatToOtherUser", data)
// 群聊——发送
export const chatToGroup = data => post("/cashBack/chatToGroup", data)
// 获取私聊记录
export const getHistoryChatFriendsMessage = data => post("/cashBack/historyChatFriendsMessage", data)
// 创建群组
export const createGroup = data => post("/cashBack/createGroup", data)
// 获取群聊记录
export const historyChatGroupMessage = data => post("/cashBack/historyChatGroupMessage", data)
// 群用户信息
export const chatGroupUserInfo = data => get("/cashBack/chatGroupUserInfo", data)
// 添加好友到群组
export const inviteGroup = data => post("/cashBack/inviteGroup", data)
// 退出群聊
export const leaveGroup = data => get("cashBack/leaveGroup", data)
// 保存群聊名称
export const updateChatGroupName = data => post("/cashBack/groupUpdateName", data)
// 删除首页聊天
export const delChatList = data => get("/cashBack/delChatList", data)
// 踢出群聊
export const kickOutGroup = data => post("/cashBack/kickOutGroup", data)
// 删除好友
export const deleteFriend = data => get("/cashBack/deleteFriend", data)
// 操作类型, 1: 置顶 2: 取消置顶 3: 设置免打扰 4: 取消免打扰
export const chatListOperate = data => post("/cashBack/chatListOperate", data)

// 查询群聊
export const searchGroup = data => get("/cashBack/search", data)
// 加入群聊
export const joinGroup = data => get("/cashBack/joinGroup", data)
// 离开聊天界面焦点
export const leaveChatFocus = data => get("/cashBack/leaveChatFocus", data)
// 获取新好友申请列表
export const friendApplyRecord = data => get("/cashBack/friendApplyRecord", data)
// 设置为管理员
export const groupSetAdmin = data => post("/cashBack/groupSetAdmin", data)
// 将用户禁言
export const groupForbidden = data => post("/cashBack/groupForbidden", data)
// 设置好友备注
export const friendChangeRemarkName = data => get("/cashBack/friendChangeRemarkName", data)
// 上传图片
export const upload = data => form("/cashBack/upload", data)
// 管理员上传头像
export const groupUpdateAvatar = data => post("/cashBack/groupUpdateAvatar", data)
// 管理员清空记录
export const groupDeleteMsg = data => post("/cashBack/groupDeleteMsg", data)
// 修改群消息发送间隔
export const groupUpdateMsgIntervalTime = data => post("/cashBack/groupUpdateMsgIntervalTime", data)
// 获取客服列表
export const customerService = data => post("/cashBack/customerServiceInfo", data)
// 翻译文本
export const translation = data => post("/cashBack/translation", data)

// 设置进群方式
export const groupSetJoinType = data => post("/cashBack/groupSetJoinType", data)
// 查看群组申请记录
export const groupApplyRecord = data => post("/cashBack/groupApplyRecord", data)
// 处理群组申请 1: 同意 2: 拒绝
export const dealGroupApply = data => get("/cashBack/dealGroupApply", data)
// 群组申请数量
export const groupApplyReviewCount = data => get("/cashBack/groupApplyReviewCount", data)
// 群信息
export const chatGroupDetailInfo = data => get("/cashBack/chatGroupDetailInfo", data)

// 红包功能 ↓
// 发送红包记录
export const sendRecord = data => post("/cashBack/red/envelope/send/record", data)
// 领取红包记录
export const receiveRecord = data => post("/cashBack/red/envelope/receive/record", data)
// 红包人气榜
export const receiveRank = data => post("/cashBack/red/envelope/rank", data)
// 领取红包
export const receiveGrab = data => post("/cashBack/red/envelope/grab", data)
// 红包详情
export const receiveDetail = data => get("/cashBack/red/envelope/detail", data)
// 用户余额
export const sendDetailInfo = data => get("/cashBack/before/send/red/envelope", data)
