import { mapState, mapActions } from "vuex";
export default {
  props: ["leftIcon", "name", "isLeftIcon", "bg", "color", "centerImg", "isLeftOpen", "rightText", "rightUrl", "rightImg", "rightImg2", "rightUrl2"],
  data() {
    return {};
  },
  computed: {
    ...mapState("ws", ["wsStatus"])
  },
  mounted() {},
  methods: {
    ...mapActions(["toPath"]),
    toLeftOpen() {
      this.$emit("toLeftOpen");
    },
    torRightOpen() {
      this.$emit("torRightOpen");
    },
    toRightUrl() {
      this.$emit("toRightUrl");
    },
    toRightUrl2() {
      this.$emit("toRightUrl2");
    }
  }
};