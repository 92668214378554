import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      // 在每次路由切换前，将页面滚动到顶部
      window.scrollTo(0, 0);
    }
  },
  computed: {
    ...mapState([]),
    routeData() {
      return this.$route;
    }
  },
  created() {
    // 初始化DB
    this.createdOpenDB();
    // websocket启动
    this.createWebSocket();
    this.getUserInfo();
  },
  mounted() {
    window.addEventListener("unload", this.saveState);
  },
  methods: {
    ...mapActions(['getUserInfo']),
    ...mapActions('ws', ["createWebSocket", 'createdOpenDB']),
    saveState() {
      // 重置参数
      sessionStorage.setItem("state", JSON.stringify(this.$store.state));
    }
  }
};